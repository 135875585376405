import React from "react"
import Layout from '../components/layout-default'
import { Link } from "gatsby"

const SiteMap = () => (
  <Layout
    title="Sitemap | RF Dance"
    description="RF Dance sitemap." 
    pathname="sitemap"
    noHero="no-hero">
    <div className="content-block" style={{marginTop : -60+'px'}}>
      <div className="container">
        <h1 className="default-title">Sitemap</h1>
        <p><Link to="/">Home</Link></p>
        <p><Link to="/bachata-mondays">Bachata Mondays</Link></p>
        <p><Link to="/salsa-wednesdays">Salsa Wednesdays</Link></p>
        <p><Link to="/bachata-thursdays">Bachata Thursdays</Link></p>
        <p><Link to="/teams">Teams</Link></p>
        <p><Link to="/workshops">Workshops</Link></p>
        <p><Link to="/classes">Classes</Link></p>
        <p><Link to="/account">My Account</Link></p>
      </div>
    </div>
  </Layout>
)

export default SiteMap